import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://5e3ac7e21ca390de6c994832b4b350b1@o4507387253293056.ingest.us.sentry.io/4507387254669312",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});